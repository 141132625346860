.nav-links-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
}

.merchant-nav-wrapper {
  flex-direction: column;
  background: #2f1a43;
  width: 250px;
  height: 100%;
  align-items: center;
  padding: 30px 10px;
}

.merchant-link-item-wrapper {
  display: flex;
}

.merchant-link-title {
  color: white;
  transition: 0.3s ease;
}

.merchant-link-icon {
  padding: 2px 0px;
  fill: white;
  stroke: white;
  stroke-width: 1px;
  stroke-opacity: 1px;
  transition: 0.3s ease;
}

.merchant-link-item-wrapper:hover > .merchant-link-title {
  color: #f7941f;
}

.merchant-link-item-wrapper:hover > .merchant-link-icon {
  fill: #f7941f;
  stroke: #f7941f;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
}

.merchant-active-link {
  color: #f7941f;
  fill: #f7941f;
  stroke: #f7941f;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
}

.cashier-link-item-wrapper {
  display: flex;
}

.cashier-nav-wrapper {
  flex-direction: column;
  background: #ffffff;
  width: 250px;
  height: 100%;
  align-items: center;
  padding: 30px 10px;
}

.cashier-link-title {
  color: rgba(32, 14, 50, 0.3);
  transition: 0.3s ease;
}

.cashier-link-icon {
  padding: 2px 0px;
  fill: rgba(32, 14, 50, 0.3);
  stroke: rgba(32, 14, 50, 0.3);
  stroke-width: 1px;
  stroke-opacity: 1px;
  transition: 0.3s ease;
}

.cashier-link-item-wrapper:hover > .cashier-link-title {
  color: #3b2353;
}

.cashier-link-item-wrapper:hover > .cashier-link-icon {
  fill: #3b2353;
  stroke: #3b2353;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
}

.cashier-active-link {
  color: #3b2353;
  fill: #3b2353;
  stroke: #3b2353;
  stroke-width: 1.5px;
  stroke-opacity: 0.7;
}

.bottom-card-btn {
  background: #f79420;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 5px 0px;
  display: flex;
  justify-content: center;
}

@media (max-width: 760px) {
  .merchant-nav-wrapper,
  .cashier-nav-wrapper {
    width: 100%;
  }
  .cashier-nav-wrapper {
    background: transparent;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .cashier-link-title {
    color: #fff;
  }
  .cashier-link-icon,
  .logout-icon {
    fill: #fff;
    stroke: #fff;
  }
  .cashier-link-item-wrapper:hover > .cashier-link-title,
  .cashier-active-link {
    color: #f7941f;
  }
  .cashier-link-item-wrapper:hover > .cashier-link-icon,
  .cashier-active-link {
    fill: #f7941f;
    stroke: #f7941f;
  }
}
