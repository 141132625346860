.loyalty-top-cards {
  margin-bottom: 50px;
}

.loyalty-card-container {
  height: 170px;
  width: 480px;
  background-color: #fff7fc;
  border: 0.5px solid #e4e7ec;
  padding: 32px;
  border-radius: 3px;
}
.loyalty-dropdown-container .ant-select {
  border: 0.2px solid rgba(25, 0, 65, 0.26) !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  height: 100% !important;
  font-size: 15px !important;
  outline: none !important;
  width: 150px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.loyalty-dropdown-container .ant-select-arrow > span > svg {
  color: #000000;
  font-size: 12px;
  padding-top: 2px;
}
.loyalty-dropdown-container .ant-select-selection-placeholder {
  line-height: 27px !important;
}
.loyalty-dropdown-container .ant-select-selection-item,
.loyalty-dropdown-container .ant-select-selection-placeholder {
  font-family: 'PlusJarkataMedium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #676e76;
  font-size: 12px;
  font-weight: 300;
}
.ant-select-borderless.loyalty-borderless {
  outline: none !important;
  border: 0.1px solid #f0f2f5;
}
.loyalty-card-content {
  height: 100%;
}
.loyalty-analytics-section {
  width: 38vw;
  height: 50vh;
}
.trans-analytics-container {
  background-color: #fff7fc;
}

@media (max-width: 760px) {
  .loyalty-top-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .loyalty-card-container {
    width: 100%;
    height: 80%;
    padding: 15px;
    margin-bottom: 10px;
  }
  .loyalty-dropdown-container .ant-select {
    font-size: 13px !important;
    outline: none !important;
    width: 100px;
  }
  .loyalty-dropdown-item {
    font-size: 12px !important;
    font-weight: 600;
  }
  .paddingTop5 {
    padding-top: 5px;
  }
  .sm-header {
    font-size: 17px;
  }
  .trans-analytics-container {
    background-color: #fff;
    /* margin-bottom: 10px !important; */
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .trans-analytics-content {
    display: flex;
    flex-direction: column;
  }
  .loyalty-analytics-section {
    width: 100%;
    min-height: 190px;
    height: 100%;
  }
  .sm-graphHeader {
    font-size: 13px;
  }
  .loyalty-graph-container {
    border: 0.44px solid #e4e7ec;
  }
}
