/*
    Ripple effect gotton from csstricks at 
    https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons 
    Thank you so much

*/

.app-button {
  border: none;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  background-position: center;
  transition: background 0.8s;
  cursor: pointer;
}

.app-button:focus {
  outline: none;
}
