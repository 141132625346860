.tour-bg {
  z-index: 100;
  background-color: rgba(47, 26, 67, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.wrapper-widget {
  padding: 12px;
  z-index: 200;
}

.white-bg {
  background-color: white;
}
.shaded-bg {
  background-color: #3b2353;
}

.tour-content {
  display: none;
}

.tour-active .tour-content {
  display: block;
  transition: display 0.5s ease-in;
  z-index: 200;
  width: 300px;
  padding: 20px 15px 15px 15px;
  background: white;
  border-radius: 10px;
  margin-top: 40px;
  transition: top 0.5s ease-in;
}

.tour-button-left,
.tour-button-right {
  padding: 15px 50px;
  color: white;
}

.tour-button-left {
  background-position: center;
  background-color: transparent;
  border: 0.5px solid white;
  color: white;
  transition: background 0.8s;
}
.tour-button-left:hover {
  background: #958a9f radial-gradient(circle, transparent 1%, #958a9f 1%) center/15000%;
}
.tour-button-left:active {
  background-color: #fff6f6;
  background-size: 100%;
  transition: background 0s;
}

.tour-button-right {
  background-position: center;
  background-color: var(--color-action);
  transition: background 0.8s;
}
.tour-button-right:hover {
  background: var(--color-action) radial-gradient(circle, transparent 1%, var(--color-action) 1%) center/15000%;
}
.tour-button-right:active {
  background-color: #f58388;
  background-size: 100%;
  transition: background 0s;
}

.tour-actions {
  bottom: 20%;
  left: 50%;
}
.tour-active div.filter-nav,
.tour-active div.dashboard-header {
  position: unset;
}
.disable-widget {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.tour-content::after {
  position: absolute;
  content: '';
  border-bottom: 20px solid white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  top: -20px;
}

.tour-triangle-right::after {
  right: 40px;
}
.tour-triangle-left::after {
  left: 40px;
}

.progress-container {
  width: 100%;
  background-color: rgba(47, 26, 67, 0.5);
}
.progress-inner {
  background-color: var(--color-action);
}

.progress-container,
.progress-inner {
  height: 5px;
}
.z-index200 {
  z-index: 200;
}
.wrapper-widget {
  border-radius: 10px;
}
