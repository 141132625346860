.content-wrapper {
  padding: 0px 40px;
}

.card-dropdown {
  background-color: #3b2353;
  color: white;
  margin: 20px 0;
  text-align: left;
}

.top-bottom-border {
  height: 50px;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
  border-top: 0.5px solid rgba(185, 185, 185, 0.3);
  display: flex;
  align-items: center;
}

.text-cut {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-icon-angle {
  font-size: 20px;
  color: #200e32;
}
.reg-container {
  width: 60%;
  margin-top: 40px;
  max-width: 700px;
}
.two-row-text p {
  margin: 0;
  white-space: nowrap;
  max-width: 600px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-nav {
  margin-top: 2px;
  background-color: white;
  z-index: 1;
  position: sticky;
  top: 82px;
  padding: 15px 40px;
}
.grey-border {
  border-top: 0.5px solid rgb(32 14 50 / 10%);
  border-bottom: 0.5px solid rgb(32 14 50 / 10%);
}
.filter-nav-search {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  padding: 5px 20px;
  height: auto;
  font-size: 16px;
}
.graph-header {
  padding: 30px 40px 20px 20px;
}
.vpay-support-button {
  background-color: #2f1a43;
  color: white;
  border-radius: 30px;
  display: none;
}
.blue-text {
  color: #0e51fe;
}

.document-upload {
  padding: 20px 30px;
  background: #f9f9f9;
  border-radius: 5px;
}

.dotted-upload {
  height: 120px;
  width: 100%;
  max-width: 220px;
  border: 1px dashed #ae207c;
}

.card-dropdown p {
  margin: 0;
}
.card-dropdown-item {
  padding: 15px 20px;
}

.below {
  position: absolute;
  background: #3b2353;
  left: 10px;
  width: 230px;
  z-index: 999;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  -moz-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
}
.card-dropdown svg {
  font-size: 20px;
}
.merchant-name {
  font-size: 14px;
}

.recharts-surface {
  padding: 0;
}

.merchant-name ~ p {
  color: rgba(255, 255, 255, 0.5);
}

.trans-item-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
  padding: 30px 15px 20px 15px;
  cursor: pointer;
}
.virtual-item-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
  padding: 30px 15px 20px 15px;
}

/* 
animation for slide up */
.slide-up {
  animation: slide-up 0.2s linear;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.item-fadeout {
  transform: translateX(100%);
  display: none;
  animation: fadeout 0.3s linear;
}

/* 
animation for slide up */

.trans-item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.trans-item .bottomLeft {
  font-size: 13px;
  opacity: 0.5;
}
.trans-item .topLeft {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.trans-item .bottomRight {
  font-size: 13px;
}

.align-end {
  align-items: flex-end;
}

.date-row {
  background: #ab388123;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desktop-date-row {
  flex-direction: row-reverse;
  margin: 0 -20px;
  padding: 5px 13.5% 5px 21px;
}

.failedTag {
  color: rgb(237, 43, 48);
  background: rgb(237 43 48 / 19%);
  font-weight: 800;
  padding: 2px 10px !important;
  border-radius: 2px;
}
.pendingTag {
  color: #f79420;
  background: #f793205e;
  font-weight: 800;
  padding: 2px 10px !important;
  border-radius: 2px;
}
.checked {
  background: rgba(174, 32, 124, 0.03);
}
.radio-label,
.app-radio input[type='radio'],
.checkmark {
  cursor: pointer;
}
.app-radio input[type='radio'] {
  position: absolute;
  opacity: 0;
  right: 1.5rem;
  z-index: 2;
  height: 2rem;
  width: 2rem;
}
.checkmark {
  position: absolute;
  right: 1.5rem;
  height: 20px;
  width: 20px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid rgba(32, 14, 50, 0.5);
}
.app-radio input[type='radio']:checked ~ .checkmark {
  background: #ed2b30;
  border-color: #ed2b30;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-radio input[type='radio']:checked ~ .checkmark::after {
  content: url('../../assets/images/checkmark.svg');
  width: 10px;
}
input[name='yesterday']:checked + label {
  font-size: 20px;
}
.btn-refresh {
  background: transparent;
  border: 0;
  display: flex;
  padding: 4px;
}
.btn-refresh img {
  width: 20px;
}
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}
.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}
.ant-picker-panels > *:last-child {
  display: none;
}
.challenge-color {
  color: #b80075;
}

.filter-text-wrapper {
  animation: fade-in 1.5s ease-in-out;
  transition: all 1.5s ease-in-out;
  /* border-top: 0.5px solid #200e3240; */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* desktop merchant cashpoint style */
.desktop-merchant-cashpoint-trans {
  max-height: 650px;
  overflow: auto;
}
.desktop-merchant-cashpoint-trans .trans-header p,
.cashier-trans .trans-header p {
  padding: 1rem 0 !important;
  width: 20%;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
}
.desktop-merchant-cashpoint-trans .trans-item-wrapper,
.cashier-trans .trans-item-wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}

.desktop-merchant-cashpoint-trans .virtual-item-wrapper,
.cashier-trans .virtual-item-wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}
.desktop-merchant-cashpoint-trans .trans-item-wrapper > div,
.cashier-trans .trans-item-wrapper > div {
  width: 20%;
  padding-right: 1rem !important;
}
.desktop-merchant-cashpoint-trans .virtual-item-wrapper > div,
.cashier-trans .virtual-item-wrapper > div {
  width: 20%;
  padding-right: 1rem !important;
}
.desktop-merchant-cashpoint-trans .trans-item-wrapper .name,
.cashier-trans .trans-item-wrapper .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.desktop-merchant-cashpoint-trans .virtual-item-wrapper .name,
.cashier-trans .virtual-item-wrapper .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.border-bottom {
  border-bottom: 2px solid #b9b9b94d;
}
.radioBtn {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #999;
  transition: 0.2s all linear;
}

.radioBtn:checked {
  border: 8px solid #ff1c26;
}
.sticky-btn {
  bottom: 01px;
}

/* style calendar */
.ant-picker-dropdown {
  top: 18rem !important;
  left: 50rem !important;
}

.dateRangePicker .ant-picker-panel:nth-child(2) {
  display: none;
}

.dateRangePicker .ant-picker-panel:nth-child(1) button {
  visibility: visible !important;
}

@media (max-width: 760px) {
  .below {
    width: 93.8%;
  }
  .content-wrapper {
    padding: 0px 15px;
    height: max-content;
  }
  .recharts-responsive-container {
    height: 300px !important;
  }
  .graph-header {
    padding: 5px;
  }
  .filter-nav {
    z-index: 0;
    display: none;
  }

  .font-size-16 {
    font-size: 16px;
  }
  .reg-container {
    width: 100%;
  }

  .document-upload {
    padding: 20px 15px;
  }

  .filter-text-wrapper {
    padding-left: 10px !important;
  }
  .custom-calendar-wrapper {
    left: 35px !important;
    z-index: 2 !important;
  }
  .desktop-date-row {
    padding: 5px 9.5% 5px 21px;
  }
}
