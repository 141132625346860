.red-area {
  z-index: 99999;
  background-color: #ff1c26;
  padding: 15px 50px;
  top: 0;
  right: 0;
  position: fixed;
  width: calc(100% - 250px);
}

.r-btn {
  background-color: rgba(25, 0, 65, 0.2);
}

@media (max-width: 760px) {
  .red-area {
    padding: 10px;
    width: 100%;
  }
}
.red-area2 {
  z-index: 99;
  background-color: #ff1c26;
  padding: 15px 20px;
  top: 0;
  right: 0;
  position: fixed;
  width: calc(100% - 250px);
}

.r-btn {
  background-color: rgba(25, 0, 65, 0.2);
}
.btn-bg {
  background-color: #1c1c1e;
  border-radius: 3px;
}

@media (max-width: 760px) {
  .mobile-red-alert {
    background-color: #ff1c26;
    padding: 15px 20px;
  }
  .red-area2 {
    display: none;
  }
}
