.pay-button {
  background-color: var(--color-primary) !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pay-button-container {
  border-radius: 5px;
  padding: 20px 10px;
  width: 75%;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  -moz-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
}
.popup {
  z-index: 999;
  background-color: rgba(47, 26, 67, 0.5);
  padding: 110px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup .popup-content {
  background-color: white;
  border-radius: 5px;
  max-height: 80vh;
  height: 100%;
  overflow-y: scroll;
}

.popup .popup-content button {
  background-color: rgba(25, 0, 65, 0.2);
}

.account-clip {
  background: rgba(255, 28, 38, 0.05);
  border-radius: 29px;
  padding: 3px 20px;
}

.info-overlay {
  position: absolute;
  top: 0;
  background: #f8f8f8;
  height: 20%;
}

.time_countdown span {
  color: #200e32;
  opacity: 0.5;
}

.pp-ul {
  padding: 0px 15px !important;
}

.share-apps {
  column-gap: 2em;
  flex-wrap: wrap;
  row-gap: 2em;
}

.profile-picture {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
}

@media (max-width: 760px) {
  .popup-content {
    width: 98% !important;
    padding: 15px 10px !important;
  }
  .popup .popup-content {
    min-height: 80vh;
    max-height: 90vh;
  }
  .popup {
    padding: 5px 0px;
  }
}
