.gradient-header {
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), rgba(247, 148, 32, 0.17);
  width: 100%;
}

.mobile-app {
  width: 65%;
  height: 100%;
}

.header-account-button {
  background-color: var(--color-action);
  color: white;
  font-size: 1.1em;
  padding: 10px 20px;
  text-transform: capitalize;
  font-weight: 500;
}

.header-user {
  background-image: url('../../assets/images/ebeleAdekunle.png');
}

.header-account-button:hover {
  background: var(--color-accent) radial-gradient(circle, transparent 1%, var(--color-accent) 1%) center/15000%;
}

.header-account-button:active {
  background-color: #3f4bd0;
  background-size: 120%;
  transition: background 0s ease;
}

.body-header-padding {
  padding-top: 140px;
  padding-left: 200px;
  padding-right: 150px;
}

.top-content-padding {
  padding-top: 120px;
}

.home-header-text {
  font-size: 1.2em;
  max-width: 550px;
}

.w-con {
  max-width: 500px;
  width: 100%;
}

.waitlist-email {
  width: 60%;
  border: 1px solid rgba(32, 14, 50, 0.2);
  border-radius: 5px;
  outline: none;
  padding: 0px 30px;
  height: 45px;
  opacity: 0.7;
  font-size: 1.1em;
  background-color: #ffffff;
}

/* bad practise but abeg let me use duplicate it first 😅 😅 😅 😅 😅 */

.list-email {
  border: 1px solid rgba(32, 14, 50, 0.2);
  border-radius: 5px;
  outline: none;
  padding: 0px 30px;
  opacity: 0.7;
  font-size: 1.1em;
  background-color: #ffffff;
}

input::-webkit-input-placeholder {
  color: rgba(32, 14, 50, 0.38);
  /*Change the opacity between 0 and 1*/
}

.join-waitlist-btn {
  background-color: var(--color-action);
  border-radius: 5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  margin-left: -5px;
  z-index: 2;
  font-size: 1.1em;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.join-waitlist-btn:hover {
  background: var(--color-accent);
}

/*Media query*/
@media (max-width: 760px) {
  .body-header-padding {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    text-align: center;
  }

  .header-rider {
    margin-top: 20px;
  }

  .header-action {
    margin-top: 20px;
    flex-direction: column;
  }

  .header-account-button {
    margin-bottom: 20px;
    font-size: 1.3em;
  }

  .header-testimony {
    margin-top: 40px;
  }

  .user-testimony {
    justify-content: center;
  }

  .home-header-text {
    font-size: 1.3em;
  }

  .home-header-bt {
    font-size: 4em;
  }

  .waitlist-email {
    width: 100%;
  }

  .join-waitlist-btn {
    margin-top: 15px;
    width: 100%;
  }
}
