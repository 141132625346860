.contact-wrapper {
  padding-top: 100px;
  /* overflow: hidden; */
}

.contact-padding {
  padding-left: 200px;
  padding-right: 200px;
}

.contact-text {
  padding-right: 50px;
}
.contact-subject {
  width: calc(100vw - 345px);
  max-width: 1240px;
  outline: none;
}
.contact-message {
  height: 250px;
  max-height: 350px;
  width: calc(100vw - 345px);
  outline: none;
  max-width: 1240px;
}
.contact-subject:focus {
  border: 1.8px solid #ae207b;
}
.contact-message:focus {
  border: 1.8px solid #ae207b;
}
.contact-button {
  background-color: var(--color-accent);
  color: white;
  width: 15rem;
  padding: 15px;
}

.contact-button:hover {
  background: var(--color-accent) radial-gradient(circle, transparent 1%, var(--color-action) 1%) center/15000%;
}
.contact-button:active {
  background-color: var(--color-action);
  background-size: 120%;
  transition: background 0s ease;
}

.gradient-overlay {
  top: 0;
  right: 0;
}

.card-grid {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: -50px;
  justify-content: center;
  max-width: calc(100vw - 400px);
}

.gradient-padding {
  background: linear-gradient(90deg, #ab3882 13.19%, #ff1c26 58.4%, #f79420 100%);
  padding: 0px 200px;
  row-gap: 15px;
}

.page-header-content {
  font-size: 20px;
  max-width: 900px;
}

/* .support-title{
    font-size: 30px;
} */

/*Media query*/
@media (max-width: 760px) {
  .contact-wrapper {
    padding-top: 80px;
  }
  .contact-text {
    padding-right: 0;
    text-align: justify;
  }

  .contact-subject {
    width: 100%;
  }
  .contact-message {
    width: 100%;
  }

  .card-grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-top: 50px;
    justify-content: center;
  }
  .gradient-padding {
    height: 300px;
    background: linear-gradient(90deg, #ab3882 13.19%, #ff1c26 58.4%, #f79420 100%);
    padding: 0px 20px;
  }

  .page-header-content {
    font-size: 1.3em;
  }
  .contact-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-button {
    width: 100%;
  }
}
