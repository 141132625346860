.cashpoint-card {
  background: #ffffff;
  border: 0.5px solid rgba(32, 14, 50, 0.15);
  width: 320px;
  padding: 40px 20px;
  -webkit-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  -moz-box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
  box-shadow: 0px 4px 16px 0px rgba(50, 50, 50, 0.06);
}

.compliance-save {
  height: 50px;
}

.loan-input {
  border-bottom: 1px solid #ae207c;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
  padding: 0;
  width: 100%;
}

.loan-info-grad {
  padding: 10% 10px 0px 10px !important;
}

.loan-info-header {
  /* height: 112px; */
  border-radius: 8px;
  bottom: 54px;
  margin: 0 25px;
  padding: 15px 0;
}

.loan-info-body {
  background-color: #f9f9f9;
  padding: 0 25px;
  position: relative;
  bottom: 30px;
}

.loan-subtext {
  color: #3c3c4399;
}

.add-loan-btn {
  height: 40px;
  width: 130px;
}

.add-loan-btn-2 {
  height: 40px;
  width: 150px;
}

.min-max {
  height: 35px;
  width: 70px;
}

.loan-top-cards {
  display: flex;
}

.loan-card {
  width: 350px;
  height: 120px;
}

.loan-repay-card {
  box-shadow: 8px 8px 32px 0px #a19d9d1a;
  border-radius: 8px;
  padding: 10px 14px;
}

.loan-table-header {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 2px solid rgba(185, 185, 185, 0.3);
  border-bottom: 1px solid rgba(185, 185, 185, 0.3);
}

.bills-container {
  height: 23em;
  display: flex;
  flex-direction: column;
}

.create-cashier-input {
  width: 245px;
}

.downArrow {
  transition: 0.3s ease;
  transform: rotate(180deg);
}

.cashier-name {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cashier-balance p {
  font-size: 15px;
  font-weight: bold;
}

.cashier-balance h3 {
  font-weight: 400;
}

.cashpoint-dropdown {
  width: 370px;
  padding: 35px 20px;
  border-bottom: 0.02px solid rgba(32, 14, 50, 0.15);
  border-radius: 5px;
}

.cashier-t-card {
  display: inline-block;
}

.cashpoint-item-wrapper {
  width: 370px;
  max-width: 400px;
  overflow: scroll;
  padding: 20px;
  position: absolute;
  z-index: 2;
  box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
}

.drop-shadow {
  box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 18px 26px -6px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.edit-cashpoint {
  align-self: center;
  border: 0.5px solid rgba(32, 14, 50, 0.5);
  width: 100%;
  padding: 7px;
  text-align: center;
  border-radius: 5px;
}

.done-editing {
  background: #ed2b30 !important;
  border: none;
  padding: 15px;
  border-radius: 5px;
}

.add-cashier-form-padding {
  padding: 30px 40px 5px 40px;
}

.add-cashier-btn-wrapper {
  width: 580px;
}
.add-cashier-btn-wrapper .cancel-btn {
  background: rgba(32, 14, 50, 0.03);
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px;
  width: 120px;
}

.add-cashier-btn-wrapper .create-btn {
  background: #ff1c26;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px;
  width: 120px;
}
.add-group-btn-wrapper {
  width: 100%;
}
.add-group-btn-wrapper .cancel-btn {
  background: rgba(32, 14, 50, 0.03) !important;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px;
  width: 120px;
}

.add-group-btn-wrapper .create-btn {
  background: #ff1c26 !important;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px;
  width: 120px;
}
.check {
  width: 56px;
  height: 56px;
  background-color: #fee6e7;
  border-radius: 50%;
}
/* cashpoint  */
.purple_chip {
  border-radius: 4px;
  background: rgba(247, 233, 242, 0.8);
  padding: 4px 10px;
  color: var(--color-vpay-purple);
  font-size: 10px;
}
.stale_purple_bg {
  border-radius: 4px;
  background: rgba(247, 233, 242, 0.8);
  color: var(--color-vpay-purple);
  height: 100%;
}

.scale-1:hover {
  transform: scale(1.05);
}

.withdraw-button {
  background: #ff1c26 !important;
  border-radius: 5px;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  padding: 10px;
  width: 120px;
  cursor: pointer;
  outline: none;
  margin-left: auto;
}

.c-btn {
  border-radius: 5px;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  padding: 10px;
  width: 120px;
  cursor: pointer;
  background: #fff !important;
  outline: none;
}

.ba-b {
  border-radius: 5px;
  border: 0.5px solid rgba(32, 14, 50, 0.62);
  padding: 10px;
  cursor: pointer;
  background: #fff !important;
  outline: none;
  opacity: 0.5;
}

.pin-con {
  width: 350px;
  background: #ff1c26 !important;
  border-radius: 5px;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  padding: 15px;
  cursor: pointer;
  outline: none;
}

.float-right {
  float: right;
}

.width-350 {
  width: 350px !important;
}

.plr-20 {
  padding: 0px 20px;
}

.w-input {
  width: 100% !important;
}

.float-right {
  float: right;
}

.download-receipt-btn {
  padding: 10px 50px;
  border: 1px solid rgba(32, 14, 50, 0.15);
  background: transparent !important;
}

.ben-gradient-wrapper {
  background: linear-gradient(90deg, #ab3882 1.49%, #ff1c26 49.72%, #f79420 100%);
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.react-code-input input {
  width: 40px !important;
  height: 40px !important;
  outline: none !important;
  font-size: 17px !important;
  color: #190041 !important;
  outline: none !important;
  text-align: center !important;
  margin-right: 5px !important;
  box-shadow: none !important;
}

.profile-wrapper {
  border-radius: 5px;
  border: 0.5px solid rgba(25, 0, 65, 0.15);
  width: 55%;
  padding: 0px 60px;
}

.profile-input {
  width: 65%;
}

.profile-input input {
  height: 45px;
  margin-bottom: 0px;
  background: #fff;
}

.pin-btn {
  padding: 10px 32px;
  background: #f8f8f8;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
}

.reset-pin-btn {
  width: 190px;
  background: #ff1c26 !important;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px 15px;
}

.reset-input {
  width: 100% !important;
}

.important-full-width {
  width: 100% !important;
}

.copy-secret {
  right: 0px;
  top: 11px;
  bottom: 31px;
  font-size: 13px;
  /* padding: 12px 10px !important; */
  width: 105px !important;
}

.s-c {
  background: #ff1c26;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 6px 20px;
  width: 150px;
}

.important-p-absolute {
  position: absolute !important;
}

.react-code-input input:focus {
  border: 1px solid #ae207c !important;
}

.tab-settings {
  padding: 0px !important;
}

.width-20-percent {
  width: 25%;
}

.margin-left-auto {
  margin-left: auto;
}

.api-input > .app-input {
  margin-bottom: 0px;
}

.api-input input {
  font-size: 13px !important;
}

.support-gradient-padding {
  height: 250px;
  width: 100vw;
  background: linear-gradient(90deg, #ab3882 13.19%, #ff1c26 58.4%, #f79420 100%);
}

.gradient-background {
  background: linear-gradient(90deg, #ab3882 13.19%, #ff1c26 58.4%, #f79420 100%);
}

.suggestion-header {
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 0;
  border-radius: 5px 5px 0px 0px;
}

.message-box {
  max-height: 200px;
  min-height: 100px;
  height: 150px;
  outline: none;
  border: 0.5px solid rgba(32, 14, 50, 0.3);
}

.message-box:focus {
  border-color: #ab3882;
}

.support-gradient-overlay {
  top: 0px;
  right: 128px;
}

.trans-snippet {
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab-button {
  width: 50%;
  border: none;
  border-radius: 5px;
  color: rgba(32, 14, 50, 0.5);
  background: #fafafa !important;
  padding: 15px;
  cursor: pointer;
}

.cashlist-wrapper {
  max-height: 400px !important;
  overflow: auto;
}

.active-button-tab {
  background: #ff1c26 !important ;
  color: #fff !important;
  transition: background 0.2s ease-in-out;
}

/* bulk payment starts*/
.ant-tabs-nav {
  height: 50px;
}
.ant-tabs-content-holder {
  padding: 0 !important;
  border: 1px solidred;
}
.pad2040 {
  padding: 20px 0px 0px 30px;
}
.pad2020 {
  padding: 20px 20px 0px 20px;
}
.ant-tabs-nav {
  margin: 0 0 0 0 !important;
}
.w-30 {
  width: 30%;
}
.ptb {
  padding: 7px 20px;
}
.delBtn {
  transition: all 0.5s ease;
}
.delBtn:hover {
  background-color: #ed2b30 !important;
  color: var(--color-primary);
}

.bulk-wrapper {
  max-width: 25rem;
  height: 127.47px;
}
.bulk-wrapper::-webkit-scrollbar {
  height: 10px !important;
  width: 10px !important;
}

.bulk-card:hover::-webkit-scrollbar {
  display: block !important;
  overflow: scroll !important;
}
.bulk-card {
  min-width: 100%;
  margin-bottom: 4px;
}

.beneficiary-table {
  width: 100%;
  background: transparent;
  flex-direction: column-reverse;
}
.rm-margin {
  margin-bottom: 10px !important;
}

/* new desktop transaction list */

.desktop-history-trans {
  max-height: 90%;
  overflow: auto;
}
.desktop-history-trans::-webkit-scrollbar {
  width: 0px;
}

.desktop-history-trans-header p {
  padding: 1rem 0 !important;
  width: 20%;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
}
.desktop-history-trans .trans-item-wrapper {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
}
.desktop-history-trans .trans-item-wrapper > div {
  width: 20%;
  padding-right: 1rem !important;
}
.desktop-history-trans .trans-item-wrapper .trans-ref,
.desktop-history-trans .trans-item-wrapper .narrative {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* cashpoint */
.circle-bg {
  background-color: #fee6e7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.rm-input-padding-x {
  padding: 0px 10px !important;
}

.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
/* bulk payment ends*/

/* Referral Start */

.gb {
  padding: 0px 100px !important;
}

.earning-box {
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  width: 200px;
  max-width: 500px;
}

.unstack-div {
  margin-top: -5em;
}

.earnings-container {
  justify-content: center;
  column-gap: 7em;
}

.p-i {
  z-index: 1;
}

.ref-link-container {
  background: #ffffff;
  border: 0.5px solid rgba(25, 0, 65, 0.15);
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  max-width: 550px;
  justify-content: center;
  align-items: center;
  padding: 50px;
  overflow-y: scroll;
  max-height: 600px;
}

.ref-box {
  max-height: 500px;
  overflow: auto;
}

.ref-box::-webkit-scrollbar {
  width: 0.1px;
  height: 0.1px;
}

.copy-link-input {
  background: #ffffff;
  border: 0.35px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  border-radius: 5px;
  width: 400px;
}

.ref-link-container ._share::after {
  content: url('../../assets/images/share.svg');
  margin-left: 12px;
  font-size: 25px;
}

.ref-link-container .share-ref {
  background: rgba(174, 32, 124, 0.09);
  border-radius: 5px;
  line-height: 21px;
  color: #ae207c;
  border: none;
  width: 400px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ref-link-container .view-refs {
  background: #ff1c26;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  box-sizing: border-box;
  border-radius: 5px;
  width: 400px;
  padding: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ref-link-container .view-refs::after {
  content: '\2192';
  margin-left: 1em;
}

.ref-link-container ._b-share::after {
  content: '\2192';
  color: #ae207c;
  margin-left: 1em;
  padding-top: 2px;
}

.c-link {
  position: absolute;
  right: 15px;
  top: 25%;
}

.ref-link-container .g-b-arrow {
  top: 1.8em;
  left: 20px;
  cursor: pointer;
}

.ref-link-container .reward-price {
  color: #00b132;
}

.ref-link-container .ref-item {
  padding: 10px 0px;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
}

.payment-time-stroke {
  color: white;
  font-size: 16px;
  -webkit-text-stroke: 0.7px black;
}

.bonus-grad {
  padding: 0px 20px !important;
  height: 20em !important;
}

.box-below-text {
  font-size: 14px;
}

.addDownline {
  width: 25%;
}

.webhook-wrap {
  padding: 50px 50px;
}

.webhook-button {
  background: #ff1c26;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  box-sizing: border-box;
  border-radius: 5px;
  width: 120px;
  padding: 12px;
}

.webhook-placeholder::placeholder {
  color: #ff1c26 !important;
}

.mode-tag {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* Ends here */
/* ANTD TAB COMPONENT DESIGN */

.anticon-spin svg {
  font-size: 2em;
}

.ant-select-arrow .anticon {
  color: #200e325e !important;
}

.ant-tabs-nav-list {
  column-gap: 40px;
}

.ant-tabs-nav {
  height: 65px;
  padding: 0px 40px;
  border: 0.5px solid rgb(32 14 50 / 10%);
  background: white;
  width: 100%;
}

.ant-tabs-tab-btn {
  color: rgba(32, 14, 50, 0.3);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #200e32 !important;
}

.ant-tabs-content-holder {
  padding: 0px 40px;
  padding-bottom: 40px;
}

.ant-tabs-ink-bar {
  background: #f7941f;
}

.ant-tabs-nav {
  width: 100%;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(32, 14, 50, 0.3);
}

/* ANT SWITCH */

.ant-switch {
  height: 17px;
  line-height: 17px;
}

.ant-switch-checked {
  background-color: #ff1c26 !important;
}
.ant-switch-handle {
  top: -4px;
  left: -1px;
  height: 22px;
  width: 22px;
}
.ant-switch-handle::before {
  background-color: #f0f0f0;
}

/* CHART  RECHART */

.recharts-surface {
  padding: 0px 30px;
}

.no-desktop {
  display: none;
}

.pt-2px {
  padding-top: 2px !important;
}

.pb-7px {
  padding-bottom: 7px !important;
}

.details-grid > div:after {
  content: '';
  position: absolute;
  border-left: 0.5px solid rgba(185, 185, 185, 0.3);
  top: -33px;
  right: 50%;
  height: 100%;
}

.details-grid > div:last-child:after {
  display: none;
}

.dispute-select {
  font-weight: 400;
  font-size: 14px !important;
  height: auto !important;
}
.dispute-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding-left: 15px !important;
  height: auto !important;
}

.dispute-select,
.dispute-textarea {
  color: rgba(25, 0, 65, 1);
}

.save-wrapper {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.remark-wrapper {
  align-items: normal;
}
.remark-wrapper > p:first-child {
  margin-right: 15px !important;
}
.remark-wrapper > p:last-child {
  white-space: nowrap;
  overflow-x: auto;
}
/* virtual account radio button */
.transferCount {
  background-color: #dbdfea;
  padding: 0.9rem;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 600;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ff1c26 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #ff1c26;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #ff1c26;
}
/* virtual account radio button */

/* Team styling */
.team-wrapper {
  border-radius: 5px;
  border: 0.5px solid rgba(25, 0, 65, 0.15);
  width: 80%;
  padding: 0px 60px;
}
.btnOutline {
  border: 1px solid var(--color-action);
}
.pt-2 {
  padding-top: 2rem;
}
/* Team styling */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 760px) {
  .ref-link-container {
    border: none;
    max-height: 100%;
  }
  .add-cashier-form-padding {
    padding: 0px;
  }
  .right-container {
    width: 100%;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -15px;
  }
  .ant-carousel .slick-dots {
    z-index: 1 !important;
  }
  .ant-carousel .slick-dots li button {
    background: rgba(185, 185, 185, 0.3);
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #ae207c;
    opacity: 1;
  }
  .ant-tabs-content-holder {
    padding: 0px 15px;
  }

  .ant-tabs-nav {
    padding: 0px 15px;
  }
  .profile-wrapper {
    width: 100% !important;
    padding: 0px 30px;
  }
  .profile-input {
    width: 100%;
  }

  .copy-secret {
    top: 36px;
  }

  .mobile-pin-btn-wrap {
    width: 100% !important;
    padding-top: 12px;
  }
  .flexCol {
    flex-direction: column;
    align-items: flex-start;
  }
  .mobile-full-width {
    width: 100%;
  }

  .bills-container {
    flex-direction: row;
    height: 7em;
    background-color: #fff;
    padding: 10px;
    border: 0.5px solid rgba(32, 14, 50, 0.15);
  }
  .bills-container button {
    background-color: transparent;
  }
  .no-desktop {
    display: flex;
  }
  .react-code-input {
    display: inline-flex !important;
  }
  .tab-button {
    font-size: 12px !important;
  }
  .add-bis-wrapper {
    width: 100%;
    padding: 10px;
  }
  /* cashpoint  */
  .purple_chip {
    border-radius: 4px;
    background: rgba(247, 233, 242, 0.8);
    padding: 4px 10px;
    color: var(--color-vpay-purple);
    font-size: 10px !important;
  }
  .br-bg {
    padding: 7px;
    border-radius: 50%;
    background: rgba(255, 28, 38, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .create-cashier-input {
    width: 100%;
  }
  .mobile-flex-column {
    flex-direction: column;
  }
  .add-cashier-btn-wrapper {
    width: 100%;
  }
  .cashpoint-dropdown {
    width: 100%;
    background: linear-gradient(90deg, #ab3882 -2.86%, #ff1c26 60.36%, #f79420 114.82%);
  }
  .cashpoint-item-wrapper {
    width: 100%;
  }
  .cashier-name {
    color: #fff;
  }
  .cashier-nuban {
    /* color: white;
    opacity: 1;
    font-size: 14px; */
  }
  .cashier-transaction {
    color: #fff;
    font-weight: 700;
    font-size: 1.5em;
  }
  .mobile-gap {
    grid-gap: 0px;
  }
  .mobile-font-size-12 {
    font-size: 12px;
  }
  .mobile-font-size-16 {
    font-size: 16px;
  }
  .support-gradient-padding {
    display: block;
    text-align: center;
    padding: 0px 20px;
  }
  .support-gradient-overlay {
    right: 0px;
  }

  .earnings-container {
    flex-direction: column;
    row-gap: 2em;
    align-items: flex-start;
  }

  .p-box {
    flex-direction: column;
    width: 100%;
    row-gap: 2em;
  }

  .ref-link-container {
    padding: 20px;
  }

  .unstack-div {
    margin-top: 0em;
  }
  .gb {
    height: auto !important;
    padding: 0px 20px !important;
  }
  .earning-box {
    width: 100%;
  }
  .p-i {
    position: absolute;
    top: 0;
    right: 0;
  }
  .copy-link-input {
    width: 100%;
    padding-right: 6.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fw {
    width: 100%;
  }

  .ref-link-container .share-ref {
    width: 100%;
  }

  .ref-link-container .view-refs {
    width: 100%;
  }

  .ref-link-container.member-history .g-b-arrow {
    top: 0;
  }

  .ref-link-container.member-history > p {
    padding-top: 1rem !important;
  }

  .bonus-grad {
    height: 8em !important;
    margin: 20px;
    border-radius: 10px;
  }

  .box-below-text {
    font-size: 11px;
  }

  .bonus-earn > p {
    color: var(--color-text);
  }
  .bonus-earn {
    border: 1px solid rgba(0, 0, 0, 0.18);
  }

  .bonus-earn::last-child {
    font-size: 16px !important;
  }

  .bonus-box {
    width: 100%;
    padding: 0px 20px;
  }
  .addDownline {
    width: 30%;
  }

  .details-grid > div:after {
    display: none;
  }

  /* mobile for bulk payment starts */
  .bulk-container {
    display: flex;
    flex-direction: column;
  }
  .boxShadow {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .beneficiary-group {
    width: 100%;
    height: 100%;
  }
  .beneficiary-table {
    display: none;
  }
  .pad2040 {
    padding: 10px 10px 0px 10px;
  }
  .padtop10 {
    padding-top: 10px;
  }
  .make-row-mobile {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  .add-margin10 {
    margin: 0px 2px;
    scroll-snap-align: start;
  }
  /* mobile for bulk payment ends */

  /* mobile for loan starts */

  .loan-top-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .loan-card {
    width: 100%;
    height: 120px;
  }
  .loan-mobile-bg {
    background-color: var(--color-page-background);
  }
  /* mobile for loan ends */
  /* virtual account radio button */
  .transferCount {
    background-color: #dbdfea;
    padding: 0.9rem;
    border-radius: 50%;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #ff1c26 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #ff1c26;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #ff1c26;
  }
  .mobile-radio-group {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .mobile-group {
    display: block !important;
  }
  .flex-column-sm {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .sm-text-alignLeft {
    text-align: left;
    padding-left: 40px;
  }

  /* virtual account radio button */

  /* team roles starts */
  .team-wrapper {
    width: 95%;
    padding: 0px 10px;
    border: none;
  }
  .roles-mobile-header {
    display: flex;
    flex-direction: column;
  }
  /* team roles ends */
}

@media (max-width: 380px) {
  .bonus-box {
    flex-direction: column;
    row-gap: 1em;
  }
}

@media (max-width: 300px) {
  .bonus-grad {
    margin: -7px;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 1718px) and (min-width: 761px) {
  .display-flex {
    display: flex;
  }
  .flex-stack-col {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 990px) {
  .details-popup-content {
    width: 65% !important;
    max-width: 900px;
  }
}

@media screen and (min-width: 991px) {
  .details-popup-content {
    width: 45% !important;
    max-width: 700px;
  }
}
