.radio {
  width: 100%;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
}
.radio input,
.radio .radio-mark {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
}
/* border: 2px solid #CFCFD0;*/
.radio-mark {
  background: #fff;
  border: 2px solid #cfcfd0;
  border-radius: 50%;
}
.radio input:checked ~ .radio-mark {
  border: 0;
  background: #ff1c26;
}
.radio label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #000;
  position: relative;
}
.radio label span:last-child {
  display: inline-block;
  padding-left: 30px;
}
.benefits {
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 20px;
}
.benefits p,
.benefits span {
  font-weight: 500;
  color: #6c737b;
}
.benefits p {
  margin-bottom: 15px !important;
}
.benefits img {
  margin-right: 5px;
}
.benefits > div {
  margin-bottom: 5px;
}

@keyframes fadeInHeight {
  from {
    width: 100%;
    height: 1.5rem;
  }

  to {
    width: 100%;
    height: 30rem;
  }
}
