.clickable-area {
  margin-right: 20px;
  background-size: cover;
}

/* .clickable-area img{
    height: 20px;
} */

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.custom-file-input {
  border: none;
  height: 100%;
  width: 100%;
}
.custom-file-input:focus {
  outline: none;
}

.custom-file-input:hover::before {
  border-color: none;
}

.uploaded-image-height {
  height: auto;
  min-height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.upload-button {
  padding: 10px 25px;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  background: #ff1c26;
  border-radius: 5px;
}

.src-spinner {
  z-index: 999;
  background: #ffffff5c;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.src-spinner > svg {
  font-size: 2em;
  color: #ed2b30;
}
.imgBg {
  padding: 5px 10px;
  background-color: #fff0fa;
  border-radius: 50%;
}
/*.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}*/

/*Media query*/
@media (max-width: 760px) {
  .img-text-upload {
    margin-top: 20px;
  }
}
