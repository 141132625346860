.ant-table-thead > tr > th {
  padding: 10px !important;
}
.bulk-table-container {
  background-color: var(--color-primary);
  height: 100%;
}
.ant-input-affix-wrapper {
  border-color: #ed2b30 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #ed2b30 !important;
}

.bulk-add-button-color {
  background-color: #200e32;
}

.p-small {
  padding: 7px;
}

/* table component styling here */
.table-container {
  overflow: auto;
  height: 100vh;
}
.loyalty-table-container {
  overflow: auto;
  height: 60vh;
}

td,
th {
  border-bottom: 0.1px solid rgba(185, 185, 185, 0.3);
  padding: 15px 10px;
  text-align: left;
}
.dots:after {
  content: '\2807';
  font-size: 13px;
}

.table-header {
  padding: 0.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  background-color: #fff;
}
.beneficiary-item-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
  padding: 30px 15px 20px 15px;
}

.beneficiary-item {
  width: 100%;
  padding: 0.5rem 0 !important;
}

/* animation for beneficiary item */
.slide-up {
  animation: slide-up 0.2s linear;
}
@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* animation for beneficiary item */

.log-table-header {
  border-top: 0.1px solid rgba(185, 185, 185, 0.3);
  background-color: var(--color-primary);
}
tbody {
  overflow: hidden;
}
.table-action {
  position: absolute;
  top: 20px;
  z-index: 999;
  right: 0px;
  background-color: var(--color-primary);
  width: 9em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.table-action-item {
  text-align: center;
  padding: 10px 5px;
  position: relative;
  overflow: hidden;
}
.tr-hover:hover {
  background-color: var(--color-page-background);
}
.table-action-item:hover {
  color: white;
  background: #ed2b30;
  transition: 0.5s;
}
.empty-component-center {
  position: absolute;
  left: 0;
  right: 0;
}
.vh-50 {
  height: 50vh;
}
.groupName {
  padding-left: 1.2rem;
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 600;
  width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.count {
  background-color: #dbdfea;
  padding: 0.9rem;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 600;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableSearch {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-bottom: 1.1rem;
  padding-top: 1.1rem;
}
.hundredPx {
  width: 55%;
}
.payment-table {
  padding: 10px 15px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.log_success {
  color: #00ba48;
  text-transform: capitalize;
}
.del-icon {
  color: var(--color-action-2);
}
.fade-out {
  opacity: 0;
  transition: opacity 300ms;
}
#group-item-wrapper {
  cursor: auto;
}
.searchBeneficiary {
  border: solid 1px #807f81;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-apperance: none;
  appearance: none;
  background: #f2f2f2;
  width: 40%;
  height: 70%;
}
.redColor {
  color: #ff1c26;
}
.segment_2 {
  padding-right: 2rem;
}
/* table component styling here */
