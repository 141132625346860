.faq {
  display: none;
}

@media (max-width: 760px) {
  .faq {
    width: 100%;
    display: block;
    padding-bottom: 80px;
    height: 100%;
  }

  .faq .gradient {
    background: linear-gradient(91.66deg, #ab3882 9.63%, #ed2b30 76.56%, #f79420 110.1%);
    height: 180px;
    padding: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
  }
  .gradient .faq-overlay {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .faq-items {
    padding: 0px 20px;
    z-index: 0;
  }

  .fa-num {
    font-size: 13.5px;
  }

  .faq-search-wrapper {
    position: fixed;
    z-index: 999999;
    width: 100%;
    padding: 0px 20px;
  }

  .faq-search {
    width: 100%;
    z-index: 999999;
    border: none;
    outline: none;
    height: 50px;
    border-radius: 0px;
    padding: 10px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .faq-item {
    width: 100%;
    padding: 20px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .body-details {
    line-height: 1.7;
    width: 100%;
  }

  .body-details ul {
    padding: 20px !important;
  }

  .text-break {
    -webkit-line-clamp: 2;
    max-width: 95%;
    text-overflow: unset;
    /* text-transform: lowercase; */
  }

  /* .text-break::first-letter{
      text-transform: capitalize;
    } */

  .font-size-14 {
    font-size: 14px;
  }

  .faq-detail-body {
    padding: 20px;
  }

  .slide-in {
    animation: slide-in 0.7s ease-in;
  }

  .slide-out {
    animation: slide-out 0.7s ease-out;
  }

  @keyframes slide-out {
    0% {
      transform: translate(-120%);
    }
    25% {
      transform: translate(-120%) scale(0.8);
    }
    75% {
      transform: scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slide-in {
    25% {
      transform: scale(0.8);
    }
    75% {
      transform: translate(120%) scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: translate(120%) scale(1);
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .faq {
    width: 100%;
    display: block;
    padding-bottom: 80px;
    height: 100%;
  }

  .faq .gradient {
    background: linear-gradient(91.66deg, #ab3882 9.63%, #ed2b30 76.56%, #f79420 110.1%);
    height: 180px;
    padding: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
  }
  .gradient .faq-overlay {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .faq-items {
    padding: 0px 20px;
    z-index: 0;
  }

  .fa-num {
    font-size: 13.5px;
  }

  .faq-search-wrapper {
    position: fixed;
    z-index: 999999;
    width: 100%;
    padding: 0px 20px;
  }

  .faq-search {
    width: 100%;
    z-index: 999999;
    border: none;
    outline: none;
    height: 50px;
    border-radius: 0px;
    padding: 10px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .faq-item {
    width: 100%;
    padding: 20px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .body-details {
    line-height: 1.7;
    width: 100%;
  }

  .body-details ul {
    padding: 20px !important;
  }

  .text-break {
    -webkit-line-clamp: 2;
    max-width: 95%;
    text-overflow: unset;
    /* text-transform: lowercase; */
  }

  /* .text-break::first-letter{
      text-transform: capitalize;
    } */

  .font-size-14 {
    font-size: 14px;
  }

  .faq-detail-body {
    padding: 20px;
  }

  .slide-in {
    animation: slide-in 0.7s ease-in;
  }

  .slide-out {
    animation: slide-out 0.7s ease-out;
  }

  @keyframes slide-out {
    0% {
      transform: translate(-120%);
    }
    25% {
      transform: translate(-120%) scale(0.8);
    }
    75% {
      transform: scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slide-in {
    25% {
      transform: scale(0.8);
    }
    75% {
      transform: translate(120%) scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: translate(120%) scale(1);
    }
  }
}

@media screen and (min-width: 1024px) {
  .faq {
    width: 100%;
    display: block;
    padding-bottom: 80px;
    height: 100%;
  }

  .faq .gradient {
    background: linear-gradient(91.66deg, #ab3882 9.63%, #ed2b30 76.56%, #f79420 110.1%);
    height: 180px;
    padding: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 10;
  }
  .gradient .faq-overlay {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .faq-items {
    padding: 0px 13rem;
    z-index: 0;
  }

  .fa-num {
    font-size: 13.5px;
  }

  .faq-search-wrapper {
    position: fixed;
    z-index: 999999;
    width: 100%;
    padding: 0px 20px;
  }

  .faq-search {
    width: 100%;
    z-index: 999999;
    border: none;
    outline: none;
    height: 50px;
    border-radius: 0px;
    padding: 10px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .faq-item {
    width: 100%;
    padding: 20px 0px;
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.1);
  }

  .body-details {
    line-height: 1.7;
    width: 100%;
  }

  .body-details ul {
    padding: 20px !important;
  }

  .text-break {
    -webkit-line-clamp: 2;
    max-width: 95%;
    text-overflow: unset;
    /* text-transform: lowercase; */
  }

  /* .text-break::first-letter{
      text-transform: capitalize;
    } */

  .font-size-14 {
    font-size: 14px;
  }

  .faq-detail-body {
    padding: 20px;
  }

  .slide-in {
    animation: slide-in 0.7s ease-in;
  }

  .slide-out {
    animation: slide-out 0.7s ease-out;
  }

  @keyframes slide-out {
    0% {
      transform: translate(-120%);
    }
    25% {
      transform: translate(-120%) scale(0.8);
    }
    75% {
      transform: scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slide-in {
    25% {
      transform: scale(0.8);
    }
    75% {
      transform: translate(120%) scale(0.8);
      transition-timing-function: ease-out;
    }
    100% {
      transform: translate(120%) scale(1);
    }
  }
}
